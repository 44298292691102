#messages-page {
  .custom-input-group {
    height: 40px;
  }

  .btn-input {
    --bs-btn-color: rgba(var(--color-onyx-rgba), 0.6);
    --bs-btn-hover-color: rgba(var(--color-onyx-rgba), 0.6);
    --bs-btn-active-color: rgba(var(--color-onyx-rgba), 0.6);
    --bs-btn-bg: var(--color-white);
    --bs-btn-border-color: var(--color-pale-gray);
    --bs-btn-hover-bg: var(--color-white);
    --bs-btn-hover-border-color: var(--color-pale-gray);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-bg: var(--color-white);
    --bs-btn-active-border-color: var(--color-pale-gray);
    --bs-btn-disabled-bg: var(--color-light-silver);
    --bs-btn-disabled-border-color: var(--color-light-silver);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(var(--color-black-rgba), 0.125);
    --bs-btn-disabled-color: var(--color-white);
  }

  .error-border {
    border: 1px solid var(--color-red);
  }
}

.template-item-active {
  background-color: var(--color-off-white);
}

#message-content,
#message-preview-popup {
  img {
    max-width: 100%;
    height: auto;
  }
}

#message-popup-content {
  p {
    margin-bottom: 0;
    line-height: 1em;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

.max-h-template {
  max-height: calc(100vh - 67.5px - 8rem - 200px);

  @media screen and (max-width: 991px), (max-height: 520px) {
    max-height: 120px;
  }
}
