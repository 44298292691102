#note-list-overview {
  .note-active {
    background-color: var(--color-beige);

    .text-note {
      color: var(--color-tan);
    }
  }
  .text-note {
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-height: 100px;
    overflow-y: auto;
  }
}
