#permissions {
  .checkbox-style {
    .form-check-input[disabled] ~ .form-check-label,
    .form-check-input:disabled ~ .form-check-label {
      opacity: 0.1;
    }
  }

  #dropdown-choose-method,
  #dropdown-check-method {
    font-size: 12px;
    font-weight: 600;
  }

  .method-hover:hover {
    background-color: var(--color-off-white);
    border-radius: 4px;
  }

  #form-choose-method {
    label.form-check-label {
      margin-left: 5px;
    }
  }

  @media (min-height: 500px) {
    .custom-btn-role {
      position: fixed;
      bottom: 0;
    }
  }
}
