.meeting-attendance {
  .attendee {
    position: relative;
    display: inline-block;

    .attendee-avatar {
      border-radius: 50%;
      height: 16px;
    }

    .attendee-name {
      visibility: hidden;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--color-black);
      color: var(--color-white);
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      transition: visibility 0.2s, opacity 0.2s ease-in-out;
      opacity: 0;
      white-space: nowrap;
      z-index: 1;
    }

    &:hover .attendee-name {
      visibility: visible;
      opacity: 1;
    }
  }
}

.dropdown-meeting {
  button {
    font-size: 12px;
    font-weight: 600;
  }
}
