.dst-tabs-vertical {
  .nav-link {
    color: var(--color-black);

    &.active {
      color: var(--color-white);
      background-color: var(--color-blue);
    }
  }
  .min-h-100vh {
    min-height: calc(100vh - (67.5px + 75px + 1rem + 1px));
  }

  .nav-light {
    .nav-link {
      color: var(--color-black);

      &.active {
        color: var(--color-blue);
        background-color: var(--color-off-white);
        border: 1px solid var(--color-light-gray);
      }
    }
  }
}
