.lister-table {
  th,
  td {
    background-color: transparent;
  }

  td {
    border-style: initial;
  }
  .row-item {
    vertical-align: middle;
    color: var(--color-dark-gray);

    &.light-gray-font {
      color: var(--color-light-gray);
    }

    .status {
      &-orange {
        color: var(--color-dark-orange);
        background-color: var(--color-light-peach);
      }

      &-green {
        color: var(--color-dark-green);
        background-color: var(--color-light-green);
      }

      &-red {
        color: var(--color-dark-red);
        background-color: var(--color-light-red);
      }
      &-blue {
        color: var(--color-electric-blue);
        background-color: --color-light-blue;
      }
    }
  }
}

.pagination-controls {
  .button-lister {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
