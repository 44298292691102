.search-icon:hover {
  > path {
    stroke: var(--color-black);
  }
}

.spinner-in-text {
  height: 1em;
  width: 1em;
}

.custom-line {
  top: 15%;
  left: 20px;
}

.min-w-70 {
  min-width: 70px;
}

#certif-scrollable {
  max-width: calc(100vw - (280px + 75px + 1rem));
}

#scrollable-note-content {
  max-height: calc(100vh - (37px + 67.5px + 1rem));
  overflow-y: auto;
  overflow: scroll;
}

.user-tabs {
  max-width: calc(100vw - (280px + 75px));
}
