#permissions {
  .permissions-tab {
    > .row.permissions-panel {
      height: calc(100vh - (1rem + 1rem + 52px + 0.5rem) - 67.5px);

      .row.permissions-panel-header {
        height: 6rem;
      }

      .permissions-list {
        max-height: calc(100% - 6rem);
      }
    }
  }
}
