#note-component {
  .note-pinned {
    color: var(--color-dark-orange);
    background: var(--color-light-peach);
  }

  #note-display {
    height: calc(100vh - (67.5px + 94px + 37px + 1rem + 60.13px + 1rem + 3rem + 64px + 1rem));
    min-height: 200px;
  }

  .dropdown {
    .dropdown-toggle:after {
      display: none;
    }

    &:hover svg circle {
      fill: var(--color-black);
    }
  }
}
