.multi-filter-dropdown {
  .dropdown-toggle::after {
    display: none;
  }

  .size-dropdown {
    min-height: 130px;
    max-height: 50vh;
  }
}
