.note-active {
  background-color: var(--color-beige);

  .text-note {
    color: var(--color-tan);
  }
}

.note-preview {
  line-height: 1.5em;
}
