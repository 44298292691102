.title-note {
  border: none;
  width: 100%;
}

.content-note {
  p {
    margin-bottom: 0;
  }
}
