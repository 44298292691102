.btn-selector-right {
  border-radius: 0 5px 5px 0;
}

.btn-selector-left {
  border-radius: 5px 0 0 5px;
}

.select-selector {
  background-color: var(--color-light-blue);
  border: 1px solid var(--color-light-blue);
  border-radius: 0;
  color: var(--color-blue);
  margin: 0 2px;
  height: 28px;
}
