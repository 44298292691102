:root {
  --aa-search-input-height: 2em !important;
}

#navbar {
  border-bottom: 1px solid var(--color-light-gray);
  height: 67.5px;

  .aa-ClearButton[hidden] {
    display: block !important;
    visibility: hidden;
  }
  .aa-Input {
    font-size: 11px;
  }
}

.aa-ItemLink {
  color: var(--color-silver-gray);
  height: 100%;
  b {
    color: var(--color-black);
  }
}

.dst-header-search-source-header > i {
  line-height: 15.5px;
  vertical-align: bottom;
}

#navbar-nav {
  .navbar-nav > a {
    text-decoration: none;
    color: var(--color-silver-gray);
    padding: 10px;

    &:hover {
      svg path {
        fill: var(--color-black);
      }
      color: var(--color-black);
    }

    &.active {
      background-color: var(--color-blue);
      color: var(--color-white);
      border-radius: 5px;

      svg path {
        fill: var(--color-white);
      }
    }
  }
}

#basic-nav-dropdown + div {
  margin-top: calc(1em + 1px);
}

@media (max-width: 1199px) {
  #navbar-nav .navbar-nav {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  #navbar-nav .navbar-nav .nav-link {
    flex: 1 0 50%;
    text-align: center;
  }
}
