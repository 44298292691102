.primary,
.secondary {
  .nav-item .nav-link {
    color: var(--color-silver-gray);
    font-size: 12px;
    background-color: transparent;
  }
}

.primary .nav-item .nav-link {
  color: var(--color-silver-gray);
  font-size: 12px;

  &:hover {
    border-bottom: 2px solid var(--color-soft-purple);
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }

  &.active {
    border-bottom: 2px solid var(--color-blue);
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    color: var(--color-blue);
  }
}

.secondary {
  border-bottom: 1px solid transparent;

  .nav-item .nav-link {
    &:hover {
      border: 1px solid transparent;
      color: var(--color-black);
    }

    &.active {
      border: 1px solid transparent;
      color: var(--color-blue);
    }
  }
}
