.certification-card {
  width: 11rem;
  min-height: 9rem;
  margin-right: 20px;
  overflow: hidden;

  .dropdown {
    .dropdown-toggle:after {
      display: none;
    }
    &:hover svg circle {
      fill: var(--color-black);
    }
  }

  .regenerating-overlay {
    top: 0;
    left: 0;
    background-color: rgba(var(--color-black-rgba), 0.3);
  }
}

.max-h-4em {
  max-height: 4em;
}
