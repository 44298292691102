.dst-stat-graph {
  .bar-stat-graph {
    position: relative;
    font-size: 0.75rem;

    .hover-label {
      position: absolute;
      bottom: 140%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;
    }

    .hover-label-percentage {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;
    }

    &:hover .hover-label {
      visibility: visible;
      opacity: 1;
    }

    &:hover .hover-label-percentage {
      visibility: visible;
      opacity: 1;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    .percentage-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
