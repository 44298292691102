.dst-table {
  tr {
    .border-table {
      border-bottom: 1px solid var(--color-black) !important;
    }
    .all-modules-validated {
      background-color: var(--color-medium-green);
    }
  }
  .checkbox-cell {
    width: 50px;
  }
}
