@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

:root {
  --color-white: #ffffff;
  --color-off-white: #fafafa;
  --color-light-gray: #e9e9e9;
  --color-medium-gray: #f2f2f2;
  --color-pale-gray: #dee2e6;
  --color-light-silver: #cacaca;
  --color-silver-gray: #8f8fa8;
  --color-smoke-gray: #9c9c9c;
  --color-gray: #8c8c8c;
  --color-dark-gray: #585858;
  --color-charcoal-gray: #393939;
  --color-paynes-grey: #3f415d;
  --color-near-black: #171717;
  --color-black: #000000;

  --color-tinted-blue: #e6f5f6;
  --color-light-blue: #e9e9fb;
  --color-glaucous-blue: #597bad;
  --color-electric-blue: #326de6;
  --color-vibrant-blue: #4050ed;
  --color-blue: #4628dd;

  --color-light-green: #d1ffd9;
  --color-aqua: #56ceb2;
  --color-medium-green: #64c87a;
  --color-green: #3cd02f;
  --color-success-green: #55a957;
  --color-dark-green: #1b590a;

  --color-light-pink: #ffe7e8;
  --color-tinted-red: #f6e6e6;
  --color-light-red: #ffd1d1;
  --color-red-bright: #cf375c;
  --color-dark-coral: #d95040;
  --color-persian-red: #d02f2f;
  --color-red: #dd2828;
  --color-dark-red: #861910;

  --color-light-lavender: #f2efff;
  --color-soft-purple: #a293ee;
  --color-pink: #ce93ea;
  --color-lilas: #8b78ea;
  --color-violet: #8242ea;
  --color-magenta: #a428dd;

  --color-light-orange: #ffebb3;
  --color-light-peach: #ffd79b;
  --color-jonquil: #ffd25f;
  --color-yellow: #dba02a;
  --color-pumpkin: #f8761f;
  --color-orange: #dd7225;
  --color-dark-orange: #a86501;

  --color-beige: #f8f7f3;
  --color-tan: #d0c4a1;
  --color-brown: #b59885;
  --color-dark-brown: #694104;

  --color-white-rgba: 255, 255, 255;
  --color-black-rgba: 0, 0, 0;
  --color-red-rgba: 221, 40, 40;
  --color-blue-rgba: 70, 40, 221;
  --color-bright-ube-rgba: 207, 149, 234;
  --color-light-blue-rgba: 233, 233, 251;
  --color-onyx-rgba: 17, 17, 19;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;

  h1,
  h2,
  h3 {
    font-weight: 600;
  }

  h1,
  .h1 {
    font-size: 29.3px;
  }

  h2,
  .h2 {
    font-size: 23.44px;
  }

  h3,
  .h3 {
    font-size: 18px;
  }
}

.l-light {
  font-size: 18.75px;
  font-weight: 300;
  line-height: 40px;
}

.m-regular {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

.m-bold {
  font-size: 15px;
  font-weight: 600;
}

.s-regular {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}

.s-bold {
  font-size: 12px;
  font-weight: 600;
}

.xs-regular {
  font-size: 11px;
  font-weight: 400;
  line-height: 22px;

  input[type="checkbox"] {
    margin-top: 0.5em;
  }
}

.xs-bold {
  font-size: 11px;
  font-weight: 600;
}

.xxs-regular {
  font-size: 10px;
  font-weight: 400;
}

.xxs-bold {
  font-size: 10px;
  font-weight: 600;
}

.white-font {
  color: var(--color-white);
}

.black-font {
  color: var(--color-black);
}

.charcoal-gray-font {
  color: var(--color-charcoal-gray);
}

.silver-gray-font {
  color: var(--color-silver-gray);
}

.smoke-gray-font {
  color: var(--color-smoke-gray);
}

.dark-gray-font {
  color: var(--color-dark-gray);
}

.blue-font {
  color: var(--color-blue);
}

.electric-blue-font {
  color: var(--color-electric-blue);
}

.green-font {
  color: var(--color-green);
}

.dark-green-font {
  color: var(--color-dark-green);
}

.dark-red-font {
  color: var(--color-dark-red);
}

.red-font {
  color: var(--color-red);
}

.red-bright-font {
  color: var(--color-red-bright);
}

.orange-font {
  color: var(--color-orange);
}

.yellow-font {
  color: var(--color-yellow);
}

.tan-font {
  color: var(--color-tan);
}

.lilas-font {
  color: var(--color-lilas);
}

.gray-background {
  background-color: var(--color-gray);
}

.white-background {
  background-color: var(--color-white);
}

.off-white-background {
  background-color: var(--color-off-white);
}

.blue-background {
  background-color: var(--color-blue);
}

.electric-blue-background {
  background-color: var(--color-electric-blue);
}

.tinted-blue-background {
  background-color: var(--color-tinted-blue);
}

.light-blue-background {
  background-color: var(--color-light-blue);
}

.pink-background {
  background-color: var(--color-pink);
}

.tinted-red-background {
  background-color: var(--color-tinted-red);
}

.light-red-background {
  background-color: var(--color-light-red);
}

.orange-background {
  background-color: var(--color-orange);
}

.light-orange-background {
  background-color: var(--color-light-orange);
}

.red-background {
  background-color: var(--color-red);
}

.light-green-background {
  background-color: var(--color-light-green);
}

.beige-background {
  background-color: var(--color-color-beige);
}

.yellow-background {
  background-color: var(--color-yellow);
}

.green-background {
  background-color: var(--color-green);
}

.medium-green-background {
  background-color: var(--color-medium-green);
}

.brown-background {
  background-color: var(--color-brown);
}

.aqua-background {
  background-color: var(--color-aqua);
}

.violet-background {
  background-color: var(--color-violet);
}

.magenta-background {
  background-color: var(--color-magenta);
}

.megium-gray-background {
  background-color: var(--color-medium-gray);
}

.light-gray-background {
  background-color: var(--color-light-gray);
}

.dark-gray-background {
  background-color: var(--color-gray);
}

.content {
  height: calc(100vh - 67.5px);
  width: calc(100vw - 75px);
  left: 75px;
  position: relative;
  overflow: auto;
}

.scrollable {
  overflow: auto;
}

.clickable {
  cursor: pointer;
}

.w-fit {
  width: fit-content;
}

.rotate-180 {
  transform: rotate(180deg);
}

.min-h-50 {
  min-height: 50px;
}

.d-inherit {
  display: inherit;
}
