.certification-overview {
  min-height: 7rem;

  .card-footer {
    border: none;
  }

  .certif-container {
    height: 38vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    position: relative;
    background-image: linear-gradient(
      to bottom,
      rgba(var(--color-white-rgba), 0) 40%,
      rgba(var(--color-white-rgba), 1) 100%
    );
    padding-bottom: 15vh;
  }

  .certif-container::-webkit-scrollbar {
    display: none;
  }
}
