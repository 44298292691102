.user-activity-container {
  .calendar-insight {
    max-height: 300px;
  }
  .rmdp-range {
    background-color: var(--color-blue) !important;
  }

  .rmdp-day.rmdp-today span {
    background-color: var(--color-blue) !important;
  }

  .rmdp-week-day {
    color: var(--color-blue) !important;
  }

  .rmdp-arrow-container:hover {
    background-color: var(--color-blue) !important;
  }

  .log-list {
    border-left: 2px solid #dadada;
  }

  .see-more-button {
    border-bottom: 1px solid #cbcbcb;
    color: #cbcbcb;
  }

  .calendar-container {
    max-height: 280px;
  }
}

#popup-logs-list .log-list {
  border-left: 2px solid #dadada;
}
