.status-card {
  .circle-outline {
    width: 24px;
    height: 24px;
    border: 2px solid var(--color-pale-gray);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    .circle-filled,
    .circle-empty {
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }

    .circle-filled {
      background-color: var(--color-success-green);
    }

    .circle-empty {
      background-color: var(--color-white);
    }
  }
}

.animator-badge {
  border: 1px solid var(--color-vibrant-blue);
  color: var(--color-vibrant-blue);
}
