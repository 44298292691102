#correction-page {
  #eval-info {
    max-height: calc(100vh - (67.5px + 31px + 3rem));
  }

  #content-exam {
    height: calc(100vh - (99.5px + 31px + 1rem));
    position: relative;

    #render-spinner {
      position: absolute;
      top: calc(50% - (var(--bs-spinner-width) / 2));
      left: calc(50% - (var(--bs-spinner-width) / 2));
    }
  }

  #duration-eval {
    .progress-bar {
      background-color: var(--color-vibrant-blue);
      border-radius: 5px;
    }
  }

  #action-buttons {
    .size-action-button {
      width: 31px;
      height: 31px;

      & > .spinner-border {
        height: 1em;
        width: 1em;
      }
    }

    .users-show-eval {
      #additional-users {
        .dropdown-toggle {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  #editor-feedback-exam {
    .ql-editor {
      max-height: calc(50vh - (67.5px + 31px + 2rem));
      overflow-y: auto;
    }

    .ql-snow .ql-formats button {
      background-color: var(--color-medium-gray);
      margin-right: 5px;
      border-radius: 50%;

      &.ql-active {
        background-color: var(--color-vibrant-blue);

        & svg .ql-stroke {
          stroke: var(--color-white);
        }
      }
    }

    .ql-snow .ql-editor pre.ql-syntax {
      background-color: var(--color-off-white);
      color: var(--color-red-bright);
      border: 1px solid var(--color-light-gray);
      width: fit-content;
    }
    .ql-container.ql-snow {
      border: none;
    }
    .ql-toolbar.ql-snow {
      border-width: 0px 0px 1px 0px;
      border-style: solid;
      border-color: var(--color-light-gray);
      padding-bottom: 1em;
    }
  }

  #overlay-exam {
    box-shadow: 0 -16px 34px rgba(var(--color-black-rgba), 0.1);
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-correction-button {
    button {
      padding: 0px;
      border: 0px;
    }
  }

  .size-icon {
    width: 30px;
  }

  .custom-rating {
    font-size: 1.5em;

    .custom-rating__star {
      cursor: auto;
    }
  }
}
