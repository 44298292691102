.status {
  &.validated {
    background-color: var(--color-light-green);
    color: var(--color-dark-green);
  }
  &.in-progress {
    background-color: var(--color-light-peach);
    color: var(--color-dark-brown);
  }
  &.not-started {
    background-color: var(--color-light-red);
    color: var(--color-dark-red);
  }
  &.locked {
    background-color: var(--color-light-gray);
    color: var(--color-smoke-gray);
  }
}
.progress-module {
  --bs-progress-height: 5px;
  --bs-progress-bg: var(--color-light-green);
  --bs-progress-bar-bg: var(--color-green);
}

.course,
.module-exam {
  position: relative;
  padding-left: 10px;
}

.date {
  margin-right: 20px;
  min-width: 100px;
}

.timeline {
  position: absolute;
  left: 115px;
  top: 15px;
  bottom: -15px;
  border-left: 2px solid var(--color-white);
}

.circle {
  position: absolute;
  top: 6px;
  left: 110px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--color-white);
  background-color: transparent;
  z-index: 1;
}

.solid {
  background-color: var(--color-white);
}

.hollow {
  background-color: var(--color-blue);
}

.module-block {
  .dropdown-toggle:after {
    display: none;
  }
}
