#navbar-left {
  height: calc(100vh - 67.5px);
  width: 75px;
  background-color: var(--color-white);
  z-index: 10;
  position: fixed;
  overflow: hidden;
  transition: width 0.3s ease-in-out;

  &:hover {
    width: 250px;

    .user-details,
    .nav-text {
      width: calc(250px - 5rem);
      opacity: 1;
      overflow: initial;
    }
  }

  .user-details,
  .nav-text {
    transition: width 0.3s ease-in-out, opacity 0.5s linear;
    position: relative;
    width: 0px;
    opacity: 0;
    overflow: hidden;
    text-wrap: nowrap;

    span {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  #navbar-nav-left {
    #avatar-section {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 0;
        width: 20px;
      }

      #tag {
        color: var(--color-white);
        background-color: var(--color-aqua);
        font-size: 10px;
        padding: 2px 5px;
        width: fit-content;
        border-radius: 4px;
      }
    }

    a {
      text-decoration: none;
      color: var(--color-silver-gray);
      font-size: 13px;
      font-weight: 600;

      &:hover {
        color: var(--color-black);

        svg path {
          fill: var(--color-black);
        }
      }

      &.active {
        background-color: var(--color-light-lavender);
        color: var(--color-blue);

        svg path {
          fill: var(--color-blue);
        }
      }
    }

    .menu-icon {
      width: calc(75px - 2rem);
    }
  }
}
