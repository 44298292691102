.multi-select-live-dropdown {
  .bg-dropdown {
    background-color: var(--color-white);
    color: var(--color-blue);
    border: 1px solid var(--color-blue);
  }

  .min-w-100px {
    min-width: 100px;
  }

  .min-w-450px {
    min-width: 450px;
  }

  .me-80px {
    margin-right: 80px;
  }

  .badge {
    padding-top: 0.15em;

    .dst-icon-x {
      margin-right: -2px;
      margin-top: 2px;
    }
  }

  .dropdown-toggle.caret-hide::after {
    display: none !important;
  }
}

.aa-Panel {
  z-index: 1055 !important;
}
