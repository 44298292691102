#language-selector {
  #flag-nav {
    height: 1em;
    width: 1.5em;
    background-size: cover;
    background-position: center;
  }

  .logo-translate-login {
    font-size: 30px;
  }

  &.dropdown-toggle::after {
    display: none;
  }

  &.dropdown-menu-flag-dropdown {
    text-align: start;
    max-width: 15ch;
  }

  @media screen and (max-height: 690px), (max-width: 450px) {
    #language-footer {
      top: 0 !important;
      right: 0 !important;
      height: fit-content;
      width: fit-content !important;
    }
  }
}
