.user-profile-container {
  width: min-content;
  min-width: 280px;

  .nav-item .nav-link {
    color: var(--color-blue);
    padding: 5px;
    background-color: var(--color-light-blue);
    border-bottom: 1px solid var(--color-light-blue);
    font-size: 11px;

    &.active {
      background-color: rgba(233, 233, 251, 0.18);
      border-bottom: 1px solid var(--color-blue);
    }
  }
}

.user-cohort-violet {
  border-left: 1px solid var(--color-magenta);
}
.user-cohort-orange {
  border-left: 1px solid var(--color-orange);
}
.user-project-blue {
  border-left: 1px solid var(--color-blue);
}

.line-icons {
  width: calc(100% + 2rem);
  margin-left: -1rem;
}

.semi-circle {
  width: 100%;
  height: 96px;
  background-color: var(--color-tinted-blue);
  border-radius: 0 0 10rem 10rem;
}

#start-end-date-tooltip {
  .tooltip-inner {
    max-width: 300px;
  }
}
