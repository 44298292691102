.btn-primary {
  --bs-btn-bg: var(--color-blue);
  --bs-btn-border-color: var(--color-blue);
  --bs-btn-hover-bg: var(--color-blue);
  --bs-btn-hover-border-color: var(--color-blue);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-bg: var(--color-blue);
  --bs-btn-active-border-color: var(--color-blue);
  --bs-btn-disabled-bg: var(--color-light-silver);
  --bs-btn-disabled-border-color: var(--color-light-silver);

  &:hover {
    -webkit-box-shadow: 0px 0px 1px 5px rgba(var(--color-blue-rgba), 0.2);
    box-shadow: 0px 0px 1px 5px rgba(var(--color-blue-rgba), 0.2);
  }
}

.btn-outline-primary {
  --bs-btn-color: var(--color-blue);
  --bs-btn-border-color: var(--color-blue);
  --bs-btn-hover-bg: var(--color-blue);
  --bs-btn-hover-border-color: var(--color-blue);
  --bs-btn-active-bg: var(--color-blue);
  --bs-btn-active-border-color: var(--color-blue);
  --bs-btn-disabled-color: var(--color-blue);
  --bs-btn-disabled-border-color: var(--color-blue);
}

.btn-secondary {
  --bs-btn-color: var(--color-blue);
  --bs-btn-bg: var(--color-light-blue);
  --bs-btn-border-color: var(--color-light-blue);
  --bs-btn-hover-color: var(--color-blue);
  --bs-btn-hover-bg: rgba(var(--color-blue-rgba), 0.06);
  --bs-btn-hover-border-color: rgba(var(--color-blue-rgba), 0.14);
  --bs-btn-active-color: var(--color-blue);
  --bs-btn-active-bg: rgba(var(--color-blue-rgba), 0.06);
  --bs-btn-active-border-color: rgba(var(--color-blue-rgba), 0.06);
  --bs-btn-disabled-color: rgba(var(--color-blue-rgba), 0.5);
  --bs-btn-disabled-bg: rgba(var(--color-blue-rgba), 0);
  --bs-btn-disabled-border-color: rgba(var(--color-blue-rgba), 0);
}

.btn-danger {
  --bs-btn-bg: var(--color-red);
  --bs-btn-border-color: var(--color-red);
  --bs-btn-hover-bg: var(--color-red);
  --bs-btn-hover-border-color: var(--color-red);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-bg: var(--color-red);
  --bs-btn-active-border-color: var(--color-red);
  --bs-btn-disabled-bg: var(--color-light-silver);
  --bs-btn-disabled-border-color: var(--color-light-silver);

  &:hover {
    -webkit-box-shadow: 0px 0px 1px 5px rgba(var(--color-red-rgba), 0.2);
    box-shadow: 0px 0px 1px 5px rgba(var(--color-red-rgba), 0.2);
  }
}

.btn-info {
  --bs-btn-color: var(--color-white);
  --bs-btn-hover-color: var(--color-light-blue);
  --bs-btn-active-color: var(--color-light-blue);
  --bs-btn-bg: var(--color-pink);
  --bs-btn-border-color: var(--color-pink);
  --bs-btn-hover-bg: var(--color-pink);
  --bs-btn-hover-border-color: var(--color-pink);
  --bs-btn-focus-shadow-rgb: 207, 149, 234;
  --bs-btn-active-bg: var(--color-pink);
  --bs-btn-active-border-color: var(--color-pink);
  --bs-btn-disabled-bg: var(--color-light-silver);
  --bs-btn-disabled-border-color: var(--color-light-silver);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(var(--color-black-rgba), 0.125);
  --bs-btn-disabled-color: var(--color-light-blue);

  &:hover {
    -webkit-box-shadow: 0px 0px 1px 5px rgba(var(--color-bright-ube-rgba), 0.2);
    box-shadow: 0px 0px 1px 5px rgba(var(--color-bright-ube-rgba), 0.2);
  }
}
.active-filter-btn {
  background-color: var(--color-magenta);

  &:hover {
    background-color: var(--color-magenta);
  }
}
