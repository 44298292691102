@keyframes drop {
  0% {
    top: -50%;
  }

  100% {
    top: 110%;
  }
}

#login-page {
  height: 100vh;
  display: table;
  width: 100vw;
  background-color: var(--color-near-black);
  color: #000;
  line-height: 1.6;
  position: relative;
  font-family: sans-serif;
  overflow: hidden;

  .lines {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 125vh;
    margin: auto;
    width: 100vw;
    transform: rotate(45deg);

    .line {
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      left: 50%;
      background: rgba(var(--color-white), 0);
      overflow: hidden;

      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 15vh;
        width: 100%;
        top: -50%;
        left: 0;
        background: linear-gradient(
          to bottom,
          rgba(var(--color-white), 0) 0%,
          var(--color-white) 75%,
          var(--color-white) 100%
        );
        animation: drop 7s 0s infinite;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
      }

      &:nth-child(1) {
        left: 20%;

        &::after {
          animation-delay: 2s;
        }
      }

      &:nth-child(3) {
        left: 80%;

        &::after {
          animation-delay: 2.5s;
        }
      }
    }
  }

  #login-section {
    background-color: var(--color-white);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    min-width: 35%;
    @media screen and (max-width: 960px) {
      & {
        min-width: 80%;
      }
    }
  }
}
